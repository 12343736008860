<template>
  <div class="device" @click="openDevice">
    <span class="detail title">{{device.deviceId}}</span>
    <span class="detail">{{device.firmwareVersion}}</span>
    <span class="detail">{{device.hardwareVersion}}</span>
    <span class="detail">{{device.deviceName}}</span>
    <span class="detail lastItem">{{dateString}}</span>
    <button @click="remove"  title="delete" class="icon-btn delete-btn"><i class="material-icons">delete</i></button>
  </div>
</template>

<script>
export default {
  name: 'device-item',
  props: {
    device: Object
  },
  methods: {
    remove(e) {
      return this.$emit('remove', this.currentDevice);
    },
    openDevice(e) {
      if (e.target.innerHTML != 'delete') {
        this.$store.commit('setSelectedDevice',this.currentDevice);
            this.$router.push({name: 'user map-display'});
      }
    }
  },
  computed: {
    dateString() {
      const vm = this;
      return this.$moment(vm.device.hiveLastheardTime).format("MMM Do, YYYY HH:mm");
    },
    currentDevice() {
      return this.device;
    }
  }
}
</script>

<style lang="scss" scoped>
.device {
  display: flex;
  padding: 0 18px 0 0;
  margin-left: 18px;
  height: 60px;
  border-bottom: 1px solid #E8EAEB;
  position: relative;
  @media(max-width: 413px) {
    height: inherit;
    padding: 6px 0;
    flex-direction: column;
    > .detail {
      margin-bottom: 6px;
    }
  }
  &:hover {
    background-color: #FAFCFC;
  }
  .detail {
    flex:1 0 0;
    display: flex;
    align-items: center;
    padding-right: 20px;

    opacity: 0.8;
    color: #111215;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 18px;

    &.title {
      opacity: 1;
      font-weight: bold;
      flex: 1;
    }
    &.lastItem {
      opacity: 1;
      flex: 1.5;
    }
  }
  .delete-btn {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    i {
      color: rgba(#000, .25);
    }
  }
  &:hover {
    .delete-btn {
      opacity: 1;
      &:hover {
        i {
          color: #111215;
        }
      }
    }
  }
}
</style>

<template>
  <device-grid></device-grid>
</template>

<script>
import deviceGrid from '@/components/user/devices/device-grid'
export default {
  name: 'View_Devices',
  components: {
    deviceGrid
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="device-grid">
    <header class="super-head">
      <div class="nav">
      </div>
      <div class="ctrl">
        <button @click="$router.push({name: 'create devices'})" class="btn btn-dark">Add Device</button>
      </div>
    </header>
    <div class="table-head">
      <div v-for="(h, index) in headers"  class="heading" :key="index">
        <span>{{h.value}}</span>
        <span  v-bind:class="[sortBy === h.key ? sortDirection : 'none']" @click="sort(h.key)"></span></div>
    </div>
    <div class="table-body">
      <device v-on:remove="removeDevice(aDevice)" v-for="aDevice in pageOfItems" v-bind:key="aDevice.deviceId"
              v-bind:device="aDevice" >
      </device>
    </div>
    <div class="footer">
      <jw-pagination :items="sortedProducts" @changePage="onChangePage" :styles="customStyles" :labels="customLabels"></jw-pagination>
    </div>

  </div>
</template>

<script>
import device from './device-item';

const customStyles = {
  ul: {
    margin: 0,
    padding: 0,
    display: 'inline-block'
  },
  li: {
    display: 'inline-block',
  },
  a: {
    color: 'black'
  }
};
const customLabels = {
  first: '<<',
  last: '>>',
  previous: '<',
  next: '>'
};

export default {
  name: 'device-grid',
  components: {
    'device':device
  },
  mounted() {
    this.$store.dispatch('fetchDevices');
  },
  watch : {
    userDevices: function (val) {
      this.sortedProducts = this.sortedProduct;
    },
    sortDirection: function (val) {
      this.sortedProducts = this.sortedProduct;
    }
  },
  computed: {
    userDevices: function () {
      return this.$store.getters.userDevices;
    },
    sortedProduct: function(){
      return this.userDevices.sort((p1,p2) => {
        let modifier = 1;
        if(this.sortDirection === 'desc') modifier = -1;
        if(p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier; if(p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
        return 0;
      });
    }
  },
  methods: {
    sort: function(s){
      if(s === this.sortBy) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.sortBy = s;
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    removeDevice(device) {
      var c = confirm("Are you sure you want to remove " + device.deviceName + "?");
      if(c == true) {
        console.log('running');
        this.$store.dispatch('authUserSignIn',{
          deviceId : device.deviceId
        });
      } else {
        console.log('Device Removal Cancelled');
      }
    },
  },
  data() {
    return {
      headers: [
        {key:'deviceId',value:'Device ID'},
        {key:'firmwareVersion',value:'Firmware'},
        {key:'hardwareVersion',value:'Hardware'},
        {key:'deviceName',value:'Name'},
        {key:'hiveLastheardTime',value:'Last Heard'}
      ],
      sortedProducts: [],
      pageOfItems: [],
      customLabels,
      customStyles,
      sortBy: 'hiveLastheardTime',
      sortDirection: 'desc'
    }
  }
}
</script>

<style lang="scss" scoped>
.asc:after{
  content: "\25B2"
}

.desc:after{
  content: "\25BC"
}

.none:after{
  content: "\25C6"
}

a { cursor: pointer; }
.pagination {
  justify-content: center;
  flex-wrap: wrap;
}
.device-grid {
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid rgba(17,18,21,0.1);
  .super-head {
    height: 55px;
    display: flex;
    justify-content: space-between;
    .nav {
      display: flex;
      padding-left: 18px;
      button {
        -webkit-appearance: none;
        background: none;
        border: none;
        opacity: 0.6;
        color: #111215;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 18px;
        text-transform: uppercase;
        &.selected {
          border-bottom: 2px solid #111215;
          opacity: 1;
        }
      }
    }
    .ctrl {
      display: flex;
      align-items: center;
      padding-right: 24px;
      button {
        height: 24px;
        width: 105px;
        border-radius: 0px;
        background-color: #111215;
        border: none;
        color: #fff;
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  .table-head {
    display: flex;
    padding: 0 18px;
    height: 40px;
    border-top: 1px solid #E8EAEB;
    border-bottom: 1px solid #E8EAEB;
    margin-top: -1px;
    .heading {
      flex:1 1 0;
      opacity: 0.6;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.42px;
      line-height: 12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      padding-right: 20px;

      &:first-child {
        flex: 1;
      }
      &:last-child {
        flex: 1.5;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
}
</style>
